<template>
  <v-container fluid>
    <v-row class="pa-2">
      <v-col cols="12">
        <v-toolbar dense flat rounded>
          <v-toolbar-title>Bewerbungen ({{ bewerbungen.length }})</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-text-field
              v-model='search'
              append-icon='mdi-magnify'
              label='Suchen'
              single-line
              solo
              flat
              hide-details
              clearable
              clear-icon="mdi-close"
            ></v-text-field>
            <v-divider vertical class="px-1"></v-divider>
            <v-btn icon><v-icon @click="exportMailAdressen()">mdi-download</v-icon></v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-col>
      <v-col cols="12">
        <!-- <v-card class="mb-2 pt-1">
          <v-card-title>Statistik</v-card-title>
          <v-card-text>
            <span v-for="(item, key, index) in statistik" :key="index">
              <v-progress-linear :value="item / statistik.summe * 100" :rotate="-90" color="primary" size="80" height="24">
                {{ item }}
                {{ key }}
              </v-progress-linear>
            </span>
          </v-card-text>
        </v-card> -->
        
        <v-card class="pt-1">
          <v-tabs v-model="tab">
            <v-tab v-for="(item, key, index) in bewerbungenNachStatus" :key="index">{{ key }} ({{ item.length }})</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(item, key, index) in bewerbungenNachStatus" :key="index">
              <v-data-table
                dense
                :height="'calc(100vh - 400px)'"
                :headers="headers"
                :items="item"
                :search="search"
                fixed-header
                hide-default-footer
                :hide-default-header="$vuetify.breakpoint.xsOnly"
                :items-per-page="-1"
                no-data-text="Keine Bewerbungen vorhanden"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      <v-progress-circular  :value="bewerbungDataComplete (item) > 0.24 ? bewerbungDataComplete (item) * 100 : 0" :rotate="-90" color="primary" size="30" class="mr-2 ml-n1">
                        <v-icon v-if="bewerbungDataComplete (item) >= 1" color="green" class="pr-0">mdi-check-circle</v-icon>
                      </v-progress-circular>
                    </td>
                    <td><v-btn fab text small @click="sendManual(item)" v-if="(bewerbungDataComplete (item) >= 1) && (item.status === 'Entwurf')"><v-icon>mdi-send</v-icon></v-btn></td>
                    <td>{{ item.institution }}</td>
                    <td>{{ item.ansprechpartner }}</td>
                    <td>{{ item.data.kategorie }}</td>
                    <!-- eslint-disable-next-line -->
                    <td>{{ item.erstellt, 'DDMMYYYYhhmm' | date }} Uhr</td>
                    <!-- eslint-disable-next-line -->
                    <td><span v-if="item.abgesendet">{{ item.abgesendet, 'DDMMYYYYhhmm' | date }} Uhr</span></td>
                    <td>{{ item.status }}</td>
                    <td class="px-0" width="20px"><v-btn icon @click="(bewerbungData = item) && (showBewerbung = true)"><v-icon>mdi-pencil</v-icon></v-btn></td>
                    <td class="px-0" width="20px"><v-btn v-if="item.pdf_merge" icon @click="pdfHerunterladen(item)"><v-icon>mdi-printer</v-icon></v-btn></td>
                    <td>
                      <v-menu offset-y :close-on-content-click=false>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-avatar class="grey" size="24">{{ item.bewertungen.length }}</v-avatar>
                          </v-btn>
                        </template>
                        <v-card max-width="300px" class="pa-2">
                          <v-list dense>
                            <v-list-item v-for="(juryItem, index) in jury" :key="index">
                              <v-list-item-content>{{ juryItem.anmeldeID | user(personen) }}</v-list-item-content>
                              <v-list-item-action>
                                <v-btn icon @click="changeBewertung(juryItem.anmeldeID, item.id)" v-if="hasBewertung(juryItem.anmeldeID, item.id)"><v-icon>mdi-checkbox-outline</v-icon></v-btn>
                                <v-btn icon @click="changeBewertung(juryItem.anmeldeID, item.id)" v-else><v-icon>mdi-checkbox-blank-outline</v-icon></v-btn>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </td>
                    <!-- <td>{{ item.bewertungen ? item.bewertungen : '-' }}</td> -->
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="bewerbungData !== {}">
      <dialogBewerbung :visible.sync="showBewerbung" :data="bewerbungData" @close="showBewerbung = false"></dialogBewerbung>
    </div>

    <v-dialog
      v-model="showError"
      :width="$vuetify.breakpoint.mdAndUp ? '30%' : '90%'"
      scrollable
      persistent
    >
      <v-card>
        <v-system-bar class="warning" height="12"></v-system-bar>
        <v-card-text class="mt-6 text-center">
          {{ $t('mainDashboard.dialogerrorText') }} <br><a href="mailto:portal@german-sdg-award.de">portal@german-sdg-award.de</a>
          <br><br>
          {{ error }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="showError = false">{{ $t('schliessen') }}</v-btn>  
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import papa from 'papaparse'
  import dialogBewerbung from './dialogBewerbung'
  import { printBewerbung } from '../functions/printBewerbung.js'
  export default {
    components: {
      dialogBewerbung
    },
    name: 'mainDashboard',
    data() {
      return {
        tab: 0,
        jurySelect: [],
        showBewerbung: false,
        bewerbungData: {
          data: {
            beitrag: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
            uebertragbarkeit: [false, false, false, false],
            preis: '',
            anhang: []
          }
        },
        loading: false,
        search: '',
        headers: [
          { number: '0', text: '', align: 'left', value: '' },
          { number: '7', text: '', align: 'left', value: '' },
          { number: '1', text: 'Institution', align: 'left', value: 'institution' },
          { number: '2', text: 'Ansprechpartner', align: 'left', value: 'ansprechpartner' },
          { number: '3', text: 'Kategorie', align: 'left', value: 'data.kategorie' },
          { number: '4', text: 'Erstellt am', align: 'left', value: 'erstellt' },
          { number: '5', text: 'Abgesendet am', align: 'left', value: 'abgesendet' },
          { number: '6', text: 'Status', align: 'left', value: 'status' },
          { number: '7', text: '', align: 'left', value: '' },
          { number: '8', text: '', align: 'left', value: '' },
          { number: '9', text: '', align: 'left', value: '' }
        ],
        showError: false,
        error: null
      }
    },
    computed: {
      user () {
        return this.$store.state.user.user
      },
      personen () {
        return this.$store.state.personen.personen
      },
      jury () {
        const rollen = ['Jury']
        return this.personen.filter(item => rollen.includes(item.rolle))
      },
      bewertungen () {
        return this.$store.state.bewertungen.bewertungen
      },
      bewerbungen () {
        let bewerbungen = this.$store.state.bewerbungen.bewerbungen
        console.log(bewerbungen)
        bewerbungen.forEach(bewerbung => {
          let person = this.$store.state.personen.personen.find(item => item.anmeldeID === bewerbung.anmeldeID)
          if (person) {
            bewerbung.institution = person.data.institution
            if (person.data.titel) {
              bewerbung.ansprechpartner = person.data.titel + ' ' + person.data.nachname + ', ' + person.data.vorname
            } else {
              bewerbung.ansprechpartner = person.data.nachname + ', ' + person.data.vorname
            }
          } else {
            bewerbung.institution = 'Keine Angabe'
            bewerbung.ansprechpartner = 'Keine Angabe'
          }
        })
        return bewerbungen
      },
      bewerbungenNachStatus() {
        let bewerbungen = this.$store.state.bewerbungen.bewerbungen
        let bewerbungenGruppiert = {}

        bewerbungen.forEach(bewerbung => {
          let person = this.$store.state.personen.personen.find(item => item.anmeldeID === bewerbung.anmeldeID)
          if (person) {
            bewerbung.institution = person.data.institution
            if (person.data.titel) {
              bewerbung.ansprechpartner = person.data.titel + ' ' + person.data.nachname + ', ' + person.data.vorname
            } else {
              bewerbung.ansprechpartner = person.data.nachname + ', ' + person.data.vorname
            }
          } else {
            bewerbung.institution = 'Keine Angabe'
            bewerbung.ansprechpartner = 'Keine Angabe'
          }
          bewerbung.bewertungen = this.bewertungen.filter(item => item.bewerbungID === bewerbung.id)
        })
        bewerbungenGruppiert = bewerbungen.reduce((gruppiert, bewerbung) => {
          if (!gruppiert[bewerbung.status]) {
            gruppiert[bewerbung.status] = [];
          }
          gruppiert[bewerbung.status].push(bewerbung);
          return gruppiert;
        }, {})
        return bewerbungenGruppiert
      },
      statistik () {
        let status = {
          entwurf: 0,
          abgeschlossen: 0,
          summe: 0
        }
        this.bewerbungen.forEach(item => {
          if (item.status === 'entwurf') {
            status.entwurf++
            status.summe++
          } else if (item.status === 'abgeschlossen') {
            status.abgeschlossen++
            status.summe++
          }
        })
        return status
      }
    },
    methods: {
      async sendManual (item) {
        console.log('0')
        this.loading = true
        let bewerbungsdaten = item
        if (!this.$store.state.personen.loaded) {
          this.$store.dispatch('personen/loadPersonen')
        }
        const person = this.$store.getters['personen/personUID'](item.anmeldeID).data
        console.log(item)
        console.log(person)
        console.log('0a')
        try {
          console.log('0b')
          const answer = await printBewerbung(bewerbungsdaten, person, false)
          console.log(answer)
          bewerbungsdaten.pdf = answer;
          console.log('0c')
        } catch (error) {
          console.log('0d')
          this.error = '#mD346: ' + error;
          this.showError = true;
          bewerbungsdaten.log.push({
            text: 'error',
            date: new Date(),
            user: this.$firebase.auth().currentUser.uid
          });
          return;
        }
        console.log('1')
        try {
          const answer = await this.mergePDF(bewerbungsdaten, person)
          bewerbungsdaten.pdf_merge = answer;
          console.log('answer')
          console.log(answer)
          bewerbungsdaten.log.push({
            text: 'absenden',
            date: new Date(),
            user: this.$firebase.auth().currentUser.uid
          });
          bewerbungsdaten.status = 'Abgesendet'
          bewerbungsdaten.abgesendet = new Date()
        } catch (error) {
          this.error = '#mD359: ' + error;
          this.showError = true;
          bewerbungsdaten.log.push({
            text: 'error',
            date: new Date(),
            user: this.$firebase.auth().currentUser.uid
          });
        }
        console.log('2')
        bewerbungsdaten.nummer = 0
        this.$store.state.bewerbungen.bewerbungen.forEach(item => {
          if (item.nummer > bewerbungsdaten.nummer) {
            bewerbungsdaten.nummer = item.nummer
          }
        })
        bewerbungsdaten.nummer++
        console.log(bewerbungsdaten)
        this.$store.dispatch('bewerbungen/updateBewerbungData', bewerbungsdaten)
        this.$vars.snackbar.text = "Bewerbung wurde eingereicht."
        this.$vars.snackbar.show = true
        this.showAbsenden = false
        this.loading = false
        console.log('3')
      },
      async mergePDF (bewerbungsdaten, person) {
        console.log('merge')
        let pdfurls = []
        console.log(bewerbungsdaten.pdf)
        const url = await this.$firebase.storage().ref(bewerbungsdaten.pdf).getDownloadURL()
          .then((url) => {
            console.log(url)
            return url
          })
          .catch((error) => {
            this.error = '#mD385: ' + error
            this.showError = true
            this.showAbsenden = false
            this.loading = false
            throw new Error(error)
          });
        pdfurls.push(url)
        bewerbungsdaten.data.anhang.forEach(item => {
          pdfurls.push(item.path)
        })
        const name = person.institution.replace('.', '') + '_' + bewerbungsdaten.anmeldeID + '_merged.pdf'
        console.log('Hier Hier Hier')
        console.log(pdfurls)
        console.log(name)
        return this.$firebase.functions().httpsCallable('mergePDFs')({ urls: pdfurls, name: name })
          .then(() => {
            console.log('2abc')
            console.log('bewerbungPDFmerged/' + name)
            return this.$firebase.storage().ref('bewerbungPDFmerged/' + name).getDownloadURL()
              .then(() => {
                return 'bewerbungPDFmerged/' + name
              })
              .catch((error) => {
                this.error = '#mD393: ' + error
                this.showError = true
                this.showAbsenden = false
                this.loading = false
                throw new Error(error)
              });
          })
          .catch(error => {
            this.error = '#mD401: ' + error
            this.showError = true
            this.showAbsenden = false
            this.loading = false
            throw new Error(error)
          })
      },
      hasBewertung (juryID, bewerbungID) {
        return this.bewertungen.find(a => a.bewerbungID === bewerbungID && a.anmeldeID === juryID) ? true : false
      },
      exportMailAdressen () {
        const keys = Object.keys(this.bewerbungenNachStatus)
        const selectedKey = keys[this.tab]
        const selectedValue = this.bewerbungenNachStatus[selectedKey]

        let exportData = [[], []]
        selectedValue.forEach(bewerbung => {
          let person = this.$store.state.personen.personen.find(item => item.anmeldeID === bewerbung.anmeldeID)
          if (person) {
            if (person.data.mail) {
              exportData[0].push({
                'Unternehmen': person.data.institution,
                'Mailadresse': person.data.mail,
                'Titel': person.data.titel,
                'Vorname': person.data.vorname,
                'Nachname': person.data.nachname,
                'Telefonnummer': person.data.telefonnummer,
                'Adresse': person.data.adresse,
                'Anrede': person.data.anrede,
                'Land': person.data.land,
                'Ort': person.data.ort,
                'PLZ': person.data.plz,
                'Webseite': person.data.webseite,
                'Sprache': bewerbung.data.kooperation
              })
            }
            if (person.data.ceomail) {
              exportData[1].push({
                'Unternehmen': person.data.institution,
                'CEO Mailadresse': person.data.ceomail,
                'CEO Titel': person.data.ceotitel,
                'CEO Vorname': person.data.ceovorname,
                'CEO Nachname': person.data.ceonachname,
                'CEO Telefonnummer': person.data.ceotelefonnummer,
                'Adresse': person.data.adresse,
                'CEO Anrede': person.data.anrede,
                'Land': person.data.land,
                'Ort': person.data.ort,
                'PLZ': person.data.plz,
                'Webseite': person.data.webseite,
                'Sprache': bewerbung.data.kooperation
              })
            }
          }
        })
        var csv = papa.unparse(exportData[0], {'header': true, encoding: 'UTF-8'})
        const anchor = document.createElement('a')
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
        anchor.target = '_blank'
        anchor.download = selectedKey + '_Mailadressen Ansprechpartner.csv'
        anchor.click()
        // csv = papa.unparse(exportData[1], {'header': true, encoding: 'UTF-8'})
        // anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
        // anchor.target = '_blank'
        // anchor.download = selectedKey + '_Mailadressen CEO.csv'
        // anchor.click()
      },
      changeBewertung (juryID, bewerbungID) {
        let bewertung = this.bewertungen.find(a => a.bewerbungID === bewerbungID && a.anmeldeID === juryID)
        if (bewertung ? true : false) {
          this.$store.dispatch('bewertungen/deleteBewertungData', bewertung.id)
        } else {
          const data = {
            anmeldeID: juryID,
            erstellt: new Date(),
            bewerbungID: bewerbungID,
            fortschritt: 0,
            log: [],
            data: {
              punkte: [null, null, null, null, null, null, null, null, null, null, null, null],
              sonderpreis: false,
              anmerkung: ''
            },
            sprache: this.$i18n.locale
          }
          this.$store.dispatch('bewertungen/createBewertung', data)
        }
      },
      bewerbungDataComplete (data) {
        let count = 0
        let gesamt = 16
        if (data) {
          for (let prop in data.data) {
            if ((prop !== 'anhang') && prop !== 'globaleDimension') {
              if (data.data[prop]) {
                count++
              }
            }
          }
        }
        return (count / gesamt) ? (count / gesamt) : 0
      },
      pdfHerunterladen (item) {
        this.loading = true
        return this.$firebase.storage().ref(item.pdf_merge).getDownloadURL()
          .then(url => {
            const link = document.createElement('a')
            link.href = url
            link.target = '_blank'
            link.download = item.data.preis + ' Bewerbungsunterlagen_' + item.anmeldeID + '.pdf'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.loading = false
          })
          .catch((error) => {
            this.error = '#mD400: ' + error
            this.showError = true
            this.loading = false
            throw error;
          });
      }
    }
  }
</script>

<style>
</style>
