<template>
  <v-app class="backgroundimg">
    <v-system-bar app color="#0C1F50" class="justify-end" height="24">
      <v-btn x-small class="mr-3" :class="{ 'transparent': $i18n.locale !== 'de' }" @click="switchLanguage('de')"><img src="../static/img/locales/de_DE.png" height="12" alt="de"/></v-btn>
      <v-btn x-small class="mr-3" :class="{ 'transparent': $i18n.locale !== 'en' }" @click="switchLanguage('en')"><img src="../static/img/locales/en_GB.png" height="12" alt="en"/></v-btn>
      <v-btn x-small class="mr-3" :class="{ 'transparent': $i18n.locale !== 'fr' }" @click="switchLanguage('fr')"><img src="../static/img/locales/fr_FR.png" height="12" alt="fr"/></v-btn>
    </v-system-bar>
    <v-app-bar 
      app
      flat
      color="#0C1F50" 
      :height="$vuetify.breakpoint.mdAndUp ? '120px' : '140px'"
    >
      <v-row>
        <v-col cols="12" md="2" :class="$vuetify.breakpoint.mdAndUp ? 'text-left' : 'text-center'">
          <img src="../static/img/sdg_award_logo_dark.svg" :height="$vuetify.breakpoint.mdAndUp ? '60px' : '45px'" alt="logo"/>
        </v-col>
        <v-col cols="12" md="10" class="hidden-sm-and-down text-right">
          <span v-if="rolle === 'Mitarbeiter' | rolle === 'Administrator'">
            <v-btn v-for="(item, index) in menu" :key="index" :to="item.link" class="mr-4">
              <!-- <v-icon class="pr-2">{{ item.icon }}</v-icon> -->
              {{ item.text }}
            </v-btn>
          </span>
          <span v-if="rolle === 'Mitarbeiter' | rolle === 'Administrator'">
            <v-btn to="/jury" class="mr-4">
              Jury
            </v-btn>
          </span>
          <span v-if="rolle === 'Jury' | rolle === 'Mitarbeiter' | rolle === 'Administrator'">
            <v-btn to="/auswertung" class="mr-4">
              Auswertung
            </v-btn>
          </span>
          <v-btn  v-if="user" @click="logout()" >
            Logout
          </v-btn>
        </v-col>
        <v-col cols="12" md="10" class="hidden-md-and-up text-center">

          <span v-if="rolle === 'Mitarbeiter' | rolle === 'Administrator'">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Menü
                </v-btn>
              </template>
              <v-list nav dense>
                <v-list-item v-for="(item, index) in menu" :key="index" :to="item.link">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>  
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </span>
          <v-btn small v-if="user" @click="logout()">
            Logout
          </v-btn>
        </v-col>
      </v-row>      
    </v-app-bar>


    <v-snackbar v-model="$vars.snackbar.show" outlined color="primary" class="snackbar" absolute top center>
      {{ $vars.snackbar.text }}
    </v-snackbar>

    <v-main app class="overflow-hidden">
      <v-row class="py-0 py-md-8">
        <v-col cols="12" :md="breite ? 12 : 10" :offset-md="breite ? 0 : 1" :lg="breite ? 12 : 8" :offset-lg="breite ? 0 : 2" :xl="breite ? 12 : 6" :offset-xl="breite ? 0 : 3">
          <router-view></router-view>
        </v-col>
      </v-row>
      <!-- <router-view></router-view> -->
      <!-- <img class="rotatingLogo" src="../static/img/sdg_logo.png" alt="logo"/> -->
      <!-- <v-footer app default color="green" dark>
        <v-col cols="6" offset="3" class="text-center">
        © {{ new Date().getFullYear() }} German SDG-Award | Eine Initiative des Senat der Wirtschaft Deutschland in Zusammenarbeit mit Holtmann+ und der UNIDO | Alle Rechte vorbehalten.
        </v-col>
        <v-col cols="3" class="text-right">
          <a class="white--text pr-4" href="https://german-sdg-award.de/datenschutz/">Datenschutz</a>
          <a class="white--text" href="https://german-sdg-award.de/impressum/">Impressum</a>
        </v-col>
      </v-footer> -->
    </v-main>
    
    <!-- <v-main app class="backgroundimg">
      <v-row>
        <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3">
          <router-view></router-view>
        </v-col>
      </v-row>
      <router-view></router-view>
      <img class="rotatingLogo" src="../static/img/sdg_logo.png" alt="logo"/>
    </v-main> -->
    

    
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    //
  }),
  computed: {
    breite () {
      const allowedRoutes = ['/bewerbungen', '/nutzer', '/jury', '/auswertung']
      return allowedRoutes.includes(this.$route.path)
    },
    menu () {
      let menu = []
      menu.push({
        link: '/',
        icon: 'mdi-home',
        text: 'Dashboard'
      })
      menu.push({
        link: '/nutzer',
        icon: 'mdi-account-multiple',
        text: 'Nutzer'
      })
      menu.push({
        link: '/bewerbungen',
        icon: 'mdi-text-box',
        text: 'Bewerbungen'
      })
      return menu
    },
    user () {
      return this.$store.state.user.user
    },
    router () {
      return 'this.$router'
    },
    rolle () {
      if (!this.$store.state.personen.loaded) {
        this.$store.dispatch('personen/loadPersonen')
      }
      const user = this.$store.getters['personen/personUID'](this.$store.state.user.user)
      if (user) {
        return user.rolle
      } else {
        return false
      }
    }
  },
  methods: {
    pushRouter () {
      this.$router.push('/anmelden')
    },
    logout () {
      this.$store.dispatch('user/logout')
      this.$router.push('/anmelden')
    },
    switchLanguage(language) {
      this.$i18n.locale = language;
    }
  }
}
</script>

<style>
.rotatingLogo {
  z-index: 1;
  position: fixed;
  height: 12vw;
  right: 5%;
  bottom: 8%;
  animation: spin 120s linear infinite;
}

.backgroundimg {
  position: relative;
  z-index: 0;
  height: 100vh;
  width: 100vw;
}

.backgroundimg::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-image: url('../static/img/background.png');
  background-size: cover;
  background-position: center bottom;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
}

.snackbar {
  z-index: 10;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
</style>
